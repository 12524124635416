import { loadView } from './index';
import authGuard from './../helpers/authGuard'


export default [
    {
        path: '/delegate',
        alias: '/delegate',
        name: 'DelegateVoting',
        component: loadView('Delegate/DelegateIndex'),
        beforeEnter: authGuard,
        meta: {
            title: 'Delegate Voting'
        }
    }
]
