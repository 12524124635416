import { createApp } from "vue";
import { IonicVue } from "@ionic/vue";
import App from "./App.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import router from "./router";
import store from "./store";
import asca_api from "./services/asca_api";
import moment from "moment-timezone";
import Vue3TouchEvents from "vue3-touch-events";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import "@ionic/vue/css/core.css";
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import "./theme/variables.css";
import "./assets/css/tailwind.css";
import "./assets/css/global.css";
import initializeInterceptors from "./helpers/http.interceptor";
import { Capacitor } from "@capacitor/core";
import { Storage } from "@ionic/storage";

const storage = new Storage();
await storage.create();

async function registerFirebaseAnalytics() {
    const isWeb = Capacitor.getPlatform() === "web";

    if (isWeb) {
        await FirebaseAnalytics.initializeFirebase({
            apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
            authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.VUE_APP_FIREBASE_APP_ID,
            measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
        });
    }

    const isFirebaseAnalyticsAvailable =
    Capacitor.isPluginAvailable("FirebaseAnalytics");

    if (isFirebaseAnalyticsAvailable) {
        await FirebaseAnalytics.setCollectionEnabled({ enabled: true });
    }
}

registerFirebaseAnalytics();

//create app
const app = createApp(App)
    .use(IonicVue, {
        animated: true,
        rippleEffect: true,
    })
    .use(router)
    .use(Vue3TouchEvents, {
        swipeTolerance: 60,
    })
    .component("AppLayout", AppLayout)
    .use(store);

//http provider
app.provide("$http_asca_api", asca_api);

initializeInterceptors(store);

// check if user is logged in
const isAuthenticated = JSON.parse(await storage.get("is_authenticated"));
if (isAuthenticated) {
    await store.dispatch("conferences/getActiveConference").then(async () => {
        if (store.getters["conferences/activeConference"]) {
            moment.tz.setDefault(
                store.getters["conferences/activeConference"].timezone
            );
        }
    });
}

app.config.globalProperties.$filters = {
    timeAgo(date) {
        return moment(date).fromNow();
    },
    twelveHourFormat(date) {
        return moment(date, moment.ISO_8601).format("h:mm A");
    },
    sessionTimeslotFormat(startDate, endDate) {
        return moment(startDate, moment.ISO_8601).format("h:mm A") + moment(endDate, moment.ISO_8601).format(" - h:mm A z");
    },
    sessionDayFormat(date) {
        return moment(date, "DD-MMMM-YY").format("MMM D");
    },
    conferenceStartDateFormat(date) {
        return moment(date, "YYYY-MM-DD").format("MMMM D");
    },
    conferenceEndDateFormat(date) {
        return moment(date, "YYYY-MM-DD").format("D, YYYY");
    },
    notesFormat(date) {
        return moment(date).format("MMM D @ h:mm A");
    },
};

router.isReady().then(() => {
    app.mount("#app");
});

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
