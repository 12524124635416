import {Capacitor} from "@capacitor/core";
import {PushNotifications} from "@capacitor/push-notifications";
import {FCM} from "@capacitor-community/fcm";
import AuthService from "@/services/auth.service";
import moment from "moment-timezone";

const expiryDays = 7;

export default {
    namespaced:true,
    state: {
        fcmToken: null,
        fcmTokenExpiry: null,
    },
    mutations: {
        SET_FCM_TOKEN(state, payload) {
            state.fcmToken = payload.fcm_token
            state.fcmTokenExpiry = payload.expiry
        },
    },
    getters: {
        fcmToken: state => state.fcmToken,
    },
    actions: {
        registerPushNotifications({ commit, dispatch })
        {
            if (moment().isBefore(this.state.fcm.fcmTokenExpiry) && this.state.fcm.fcmToken) {
                return Promise.resolve(this.state.fcm.fcmToken)
            }

            getFcmToken().then((fcm_token) => {
                if (fcm_token === '') return

                const expiry = moment().add(expiryDays, 'days').format('YYYY-MM-DD HH:mm:ss')
                commit("SET_FCM_TOKEN", {fcm_token, expiry});

                AuthService.registerFcmToken(fcm_token);

                dispatch('subscribeToConferenceTopics');

                return fcm_token;
            });
        },
        subscribeToConferenceTopics({ rootGetters }) {
            const conferences = rootGetters['get_user_conferences'];

            if (conferences && conferences.length) {
                conferences.forEach(conference => {
                    FCM.subscribeTo({topic: conference.push_notification_topic})
                    if (conference.pivot.is_virtual) {
                        FCM.subscribeTo({topic: conference.push_notification_topic + '-virtual-only'})
                    } else {
                        FCM.subscribeTo({topic: conference.push_notification_topic + '-in-person-only'})
                    }
                })
            }
        }
    },
};

/**
 * Returns `true` if the user gave permission or false otherwise.
 */
async function askFcmPermission(): Promise<boolean> {
    const checked = await PushNotifications.checkPermissions()
    let status: 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied' = checked.receive

    if (status === 'prompt' || status === 'prompt-with-rationale') {
        const requested = await PushNotifications.requestPermissions()
        status = requested.receive
    }

    return status === 'granted'
}

/**
 * Gets the FCM token in a non-breaking way.
 *
 * - For iOS we must use the `FCM` plugin, because `PushNotifications` returns the wrong APN token.
 * - For Android we must use `PushNotifications`, because `FCM` is broken for Android.
 * @see https://github.com/capacitor-community/fcm/issues/99
 */
function getFcmToken(): Promise<string> {
    return new Promise((resolve, reject) => {
        if (Capacitor.getPlatform() === 'web') return resolve('')

        PushNotifications.addListener('registration', ({ value }) => {
            if (Capacitor.getPlatform() === 'android') {
                resolve(value)
                return
            }

            // Get FCM token instead the APN one returned by Capacitor
            if (Capacitor.getPlatform() === 'ios') {
                FCM.getToken()
                    .then(({ token }) => resolve(token))
                    .catch((error) => reject(error))
                return
            }

            // will never come here
            reject(new Error('?'))
        })

        askFcmPermission()
            .then((granted) => {
                if (granted) {
                    PushNotifications.register().catch((error) => reject(error))
                } else {
                    reject(new Error('denied'))
                }
            })
            .catch((error) => reject(error))
    })
}
