import { loadView } from './index';
import authGuard from './../helpers/authGuard'
export default [
    {
        path: '/dashboard',
        alias: '/dash',
        name: 'Dashboard',
        component: loadView('Dashboard/DashboardIndex'),
        beforeEnter: authGuard,
        meta: {
            title: 'Dashboard'
        }
    }
]
