import TimeslotService from "@/services/timeslot.service";
import SessionService from "@/services/session.service";
import moment from "moment-timezone";
import store from "@/store";

export default {
    namespaced:true,
    state: {
        timeslots: [],
        expiry: null,
    },
    mutations: {
        /**
       * Store timeslots
       */
        SET_TIMESLOTS(state, payload) {
            state.timeslots = payload.timeslots
            state.expiry = payload.expiry
        },
        /**
       * RESET timeslots
       */
        RESET_TIMESLOTS(state) {
            state.timeslots = []
            state.expiry = null
        },
        /**
       * Toggle user's agenda status in timeslot|session store
       */
        SET_MY_AGENDA_FOR_SESSION(state, payload) {
            store.commit('sessions/SET_MY_AGENDA_FOR_SESSION', {
                session_id:  payload.data.session.id
            }, { root: true })
        }
    },
    getters: {
        getTimeslots(state) {
            return state.timeslots;
        },
        getSessionById: (state) => (id) => {
            for (const day in state.timeslots) {
                for (const timeslots in state.timeslots[day]) {
                    for (const session in state.timeslots[day][timeslots]['sessions']) {
                        if (state.timeslots[day][timeslots]['sessions'][session].id == id) {
                            return state.timeslots[day][timeslots]['sessions'][session];
                        }
                    }
                }
            }
        },
    },
    actions: {
        /**
       * Endpoint: /client/conference/1/timeslots
       * Get all timeslots (with sessions + current user's agenda status)
       */
        async fetchTimeslotsSessions({ commit }, payload) {
            if (payload.showMessage) {
                commit('toggleLoading', { is_loading: true, message: 'Loading Session Details' }, { root: true })
            }

            if (moment().isBefore(this.state.timeslots.expiry)) {
                commit('toggleLoadingOffAbsolute', null, { root: true })
                return Promise.resolve(this.state.timeslots);
            }

            return await TimeslotService.getActiveConferenceTimeslots()
                .then(response => {
                    const timeslots = response.data
                    const expiry = moment().add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                    commit('SET_TIMESLOTS', {timeslots, expiry})
                    return Promise.resolve(response)
                }).catch(error => {
                    return Promise.reject(error)
                }).finally(() => {
                    commit('toggleLoadingOffAbsolute', null, { root: true })
                });
        },
        /**
       * Endpoint: /client/conference/1/action/session/
       * Toggle whether session is on user's agenda
       */
        async toggleSession ({ commit }, payload) {
            commit('toggleLoading', { is_loading: true, message: 'Updating agenda' }, { root: true })
            return await SessionService.toggleAgenda(payload.session.id)
                .then(response => {
                    commit('SET_MY_AGENDA_FOR_SESSION', {
                        data: payload,
                        response: response.data
                    })
                    return Promise.resolve(response)
                }).catch(error => {
                    return Promise.reject(error)
                }).finally(() => {
                    commit('toggleLoadingOff', null, { root: true })
                });
        }
    },
};
