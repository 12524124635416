import { Capacitor } from '@capacitor/core';
import { loadView } from './index';
import { StatusBar, Style } from '@capacitor/status-bar';

const isStatusBarAvailable = Capacitor.isPluginAvailable('StatusBar') && Capacitor.getPlatform() === 'ios';
export default [
    {
        path: '/maps',
        name: 'Maps',
        beforeEnter: () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Maps/MapIndex'),
        meta: {
            title: 'Maps'
        }
    },
    {
        name: 'VenueDetails',
        path: '/maps/venue/:id',
        beforeEnter: () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Maps/VenueDetails'),
        meta: {
            title: 'Venue Details'
        }
    },
    {
        name: 'VenueLevelDetails',
        path: '/maps/level/:id',
        beforeEnter: () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Maps/VenueLevelDetails'),
        meta: {
            title: 'Venue Level Details'
        }
    },
]
