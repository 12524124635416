<template>
  <MaintenanceMode :is-open="maintenance_mode.maintenance_mode" />
  <ion-loading
    :is-open="is_loading ? true : false"
    :message="loading_message"
  />
  <TransitionRoot
    as="template"
    :show="notification.is_notification ?? false"
  >
    <Dialog
      as="div"
      class="relative z-10"
      @close="closeNotificationModal"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
        />
      </TransitionChild>

      <div class="fixed inset-0 top-0 z-10 overflow-y-auto">
        <div
          class="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
            >
              <div>
                <div
                  class="flex items-center justify-center w-12 h-12 mx-auto rounded-full text-cyan-400"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-12 h-12"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900"
                  >
                    Notification Received!
                  </DialogTitle>
                  <div class="mt-6">
                    <h4 class="my-2 font-semibold text-gray-900 text-md">
                      {{ notification.notification_title }}
                    </h4>
                    <p class="text-sm text-gray-900">
                      {{ notification.notification_body }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <a
                  v-if="
                    notification.notification_data.link_text &&
                      notification.notification_data.link_url
                  "
                  type="button"
                  class="inline-flex justify-center w-full p-4 text-base font-medium text-white bg-purple-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  @click="
                    openExternalLink(notification.notification_data.link_url)
                  "
                >
                  {{ notification.notification_data.link_text }}
                </a>
                <div
                  ref="cancelButtonRef"
                  type="button"
                  class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-600 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  @click="closeNotificationModal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7 16l-4-4m0 0l4-4m-4 4h18"
                    />
                  </svg>
                  Close & Return to App
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <version-checker />
  <background-tasks />
  <push-notification-checker
    v-if="is_authenticated"
  />
  <NetworkChecker
    :primary-color="activeConference?.branding.colors.primary"
  />
</template>

<script lang="ts">
import MaintenanceMode from "@/components/MaintenanceMode.vue";
import { IonLoading } from "@ionic/vue";
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue"; //pop-up
import { Browser } from "@capacitor/browser";
import VersionChecker from "@/components/VersionChecker.vue";
import BackgroundTasks from "@/components/BackgroundTasks.vue";
import PushNotificationChecker from "@/components/PushNotificationChecker.vue";
import NetworkChecker from "@/components/NetworkChecker.vue";
import {mapState} from "vuex";
import { useStore } from "vuex";
import {Capacitor} from "@capacitor/core";
import {PushNotifications} from "@capacitor/push-notifications";

export default {
    name: "AppLayout",
    components: {
        NetworkChecker,
        PushNotificationChecker,
        BackgroundTasks,
        IonLoading,
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        MaintenanceMode,
        VersionChecker,
    },
    setup() {
        const store = useStore();
        return { store };
    },
    computed: {
        is_authenticated() {
            return this.$store.state.user.is_authenticated;
        },
        maintenance_mode() {
            return this.$store.getters["maintenance_mode"];
        },
        is_loading() {
            return this.$store.getters["current_loaders"] > 0;
        },
        notification() {
            return this.$store.getters["notification"];
        },
        loading_message() {
            return this.$store.getters["loading_message"] + "...";
        },
        ...mapState('conferences', [
            'activeConference',
        ])
    },
    mounted() {
        //
    },
    created() {
        const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

        if (isPushNotificationsAvailable) {
            PushNotifications.addListener(
                "pushNotificationReceived",
                (notification) => {
                    this.store.commit("toggleNotification", {
                        is_notification: true,
                        notification_title: notification.title,
                        notification_body: notification.body,
                        notification_data: notification.data,
                    });
                }
            );
        }
    },
    methods: {
        async openExternalLink(url) {
            await Browser.open({ url: url });
        },
        closeNotificationModal() {
            this.store.commit("toggleNotification", { is_notification: false });
        },
    },
};
</script>
