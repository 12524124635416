import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

// Import Routes
import LoginRoutes from './login';
import ForgotPasswordRoutes from './forgot-password';
import DashboardRoutes from './dashboard';
import SessionsRoutes from './sessions';
import ProfileRoutes from './profile';
import DirectoryRoutes from './directory';
import NotificationsRoutes from './notifications';
import MapRoutes from './maps';
import InformationRoutes from './information';
import DelegateRoutes from './delegate';
import AmbassadorRoutes from './ambassador';
import LeaderboardRoutes from './leaderboard';
import SlideshowRoutes from './slideshows';

import { menuController } from '@ionic/vue';
import {Capacitor} from "@capacitor/core";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";


// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
export function loadView (view) {
    return () => import(`@/views/${view}.vue`)
}

export function loadComponent (component) {
    return () => import(`@/components/${component}.vue`)
}


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/dashboard'
    },

    ...LoginRoutes,
    ...ForgotPasswordRoutes,
    ...DashboardRoutes,
    ...SessionsRoutes,
    ...ProfileRoutes,
    ...DirectoryRoutes,
    ...NotificationsRoutes,
    ...MapRoutes,
    ...InformationRoutes,
    ...DelegateRoutes,
    ...AmbassadorRoutes,
    ...LeaderboardRoutes,
    ...SlideshowRoutes,

    // 404
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: loadView('PageNotFound'),
        meta: {
            layout: 'AppLayoutDefault',
            title: 'Page Not Found'
        }
    }
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => { //ionic menu is buggy on close and doesn't like to close fully, wait for it to close before routing*/

    logScreenView(to.name.toString());
    
    //next()
    menuController.getMenus().then(response => {
        if(response.length) {            
            //store.commit('toggleLoading', { is_loading: true, message: 'Loading Page' })
            menuController.close('main').then(response => {
                //store.commit('toggleLoadingOff')
                next()
            })
        } else {
            //store.commit('toggleLoadingOff')
            next()
        }
    })
})

async function logScreenView(name) {
    if (Capacitor.isNativePlatform()) {
        await FirebaseAnalytics.setScreenName({
            screenName: name,
            nameOverride: name,
        })
    }
}

export default router
