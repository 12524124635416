import asca_api from './asca_api';
import store from '../store';

class TimeslotService {
    getActiveConferenceTimeslots() {
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        return asca_api.get('v2/client/conference/' + activeConferenceId + '/timeslots?exclude-agenda').then((response) => {
            return response
        });
    }

    getTimeslot(id) {
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        return asca_api.get('v2/client/conference/' + activeConferenceId + '/timeslot/' + id).then((response) => {
            return response
        });
    }
}

export default new TimeslotService();
