import asca_api from './asca_api';

class SupportRequestService {
    async createSupportRequest(data) {
        return asca_api.post('v2/public/support-request', {
            ...data,
        })
            .then(response => {
                return response;
            });
    }

    async getSupportRequestCategories() {
        return asca_api.get('v2/public/support-request/categories')
            .then(response => {
                return response.data;
            });
    }
}

export default new SupportRequestService();
