import { loadView } from './index';

export default [
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: loadView('ForgotPasswordIndex'),
        meta: {
            title: 'Forgot Password'
        }
    }
]
