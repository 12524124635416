import {Capacitor} from "@capacitor/core";
import {StatusBar, Style} from "@capacitor/status-bar";
import store from "@/store";

export default function authGuard(to, from, next) {
    const isStatusBarAvailable = Capacitor.isPluginAvailable('StatusBar') && Capacitor.getPlatform() === 'ios';

    if(isStatusBarAvailable) {
        // Dashboard and Auth pages use this style
        StatusBar.setStyle({ style: Style.Dark });
    }

    if(store.state.user.is_authenticated) {
        next();
    } else {
        const loginpath = window.location.pathname;
        next({ name: 'Login', query: { from: loginpath } });
        //next('/login');
    }
}
