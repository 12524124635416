// Global mutation
import {Storage} from "@ionic/storage";

export default {
    state: () => ({  }), // module state is already nested and not affected by namespace option
    getters: {
    },
    actions: {
    },
    mutations: {
        async initlaizeStore(state) {
            // Check if the ID exists
            const storage = new Storage();
            await storage.create();
            if (await storage.get('store')) {
                // Replace the state object with the stored item
                this.replaceState(
                    Object.assign(state, JSON.parse(await storage.get('store')))
                );
            }
        }
    },
}
