<template>
  <ion-alert
    :is-open="isOpen"
    header="Update Required"
    sub-header="A new version of the app is available."
    message="Please update the app to continue using it."
    :buttons="alertButtons"
    @didDismiss="setOpen(false)"
  />
</template>

<script lang="ts">
import { ref } from "vue";
import { App } from "@capacitor/app";
import { IonAlert } from "@ionic/vue";
import {
    AppUpdate,
    AppUpdateAvailability,
} from "@capawesome/capacitor-app-update";
import { Capacitor } from "@capacitor/core";

export default {
    components: { IonAlert },
    setup() {
        const isOpen = ref(false);
        const alertButtons = [
            {
                text: "Cancel",
                role: "cancel",
                handler: () => {
                    //
                },
            },
            {
                text: "Update",
                handler: () => {
                    AppUpdate.openAppStore();
                },
            },
        ];

        const setOpen = (state: boolean) => {
            isOpen.value = state;
        };

        return { isOpen, alertButtons, setOpen };
    },
    mounted() {
        App.addListener("appStateChange", (state) => {
            if (state.isActive) {
                this.checkOutdated();
            }
        });
        this.checkOutdated();
    },
    methods: {
        checkOutdated() {
            const isAppUpdateSupported = Capacitor.isNativePlatform();

            if (isAppUpdateSupported) {
                AppUpdate.getAppUpdateInfo().then((info) => {
                    if (
                        info.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE &&
                process.env.NODE_ENV === "production"
                    ) {
                        this.setOpen(true);
                    }
                });
            }
        },
    },
};
</script>
