<template>
  <ion-modal :is-open="isOpen">
    <ion-content>
      <div
        class="flex flex-col place-content-center h-full ion-padding"
        :style="primaryColor ? { 'background-color': primaryColor } : {'background-color': '#643695'}"
      >
        <div class="-mt-16">
          <img
            :src="ascaLogoLocation"
            alt="ASCA Logo"
            title="ASCA Logo"
            class="relative w-full object-contain max-w-asca-logo mx-auto mb-6"
          >
          <h2 class="font-sentient text-center text-2xl text-white mb-8">
            Down for Maintenance
          </h2>
          <p
            class="block text-center font-caption leading-5 normal-case text-neutral-200"
          >
            Sorry! We're currently down for maintenance. Please check back later.
          </p>
        </div>
      </div>
    </ion-content>
  </ion-modal>
</template>
    
<script lang="ts">
import {defineComponent, ref} from 'vue';
import {
    IonModal,
    IonContent
} from "@ionic/vue";
import defaultAssets from './../default-assets';
    
export default defineComponent({
    name: 'MaintenanceMode',
    components: {
        IonModal,
        IonContent
    }, 
    props: {
        primaryColor: {
            type: String,
            default: null
        },
        isOpen: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ascaLogoLocation() { 
            return defaultAssets.logoLocation;
        },
    },
    methods: {
    }
});
</script>
    