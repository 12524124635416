import { Capacitor } from '@capacitor/core';
import { loadView } from './index';
import { StatusBar, Style } from '@capacitor/status-bar';

const isStatusBarAvailable = Capacitor.isPluginAvailable('StatusBar') && Capacitor.getPlatform() === 'ios';
export default [
    {
        path: '/directory',
        name: 'Directory',
        beforeEnter : () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Directory/DirectoryIndex'),
        meta: {
            title: 'Directory',
            showAd: true,
        },
    },
    {
        path: '/sponsors',
        name: 'Sponsors',
        beforeEnter : () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Sponsors/SponsorIndex'),
        meta: {
            title: 'Sponsors',
            showAd: true,
        },
    },
    {
        path: '/sponsor/:sponsor_id',
        name: 'Sponsor',
        beforeEnter : () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Sponsors/Single'),
        meta: {
            title: 'Sponsor',
            showAd: true,
        }
    },
    {
        path: '/exhibitors',
        name: 'Exhibitors',
        beforeEnter : () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Exhibitors/ExhibitorIndex'),
        meta: {
            title: 'Exhibitors',
            showAd: true,
        },
    },
    {
        path: '/exhibitor/:exhibitor_id',
        name: 'Exhibitor',
        beforeEnter : function() {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Exhibitors/Single'),
        meta: {
            title: 'Exhibitor',
            showAd: true,
        }
    },
    {
        path: '/attendee/:attendee_id',
        name: 'Attendee',
        beforeEnter : () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Attendees/Single'),
        meta: {
            title: 'Attendee',
            showAd: false,
        }
    },
    {
        path: '/speaker/:speaker_id',
        name: 'Speaker',
        beforeEnter : () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Speakers/Single'),
        meta: {
            title: 'Speaker',
            showAd: false,
        }
    },
    {
        path: '/handouts',
        name: 'ConferenceHandouts',
        beforeEnter : () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Handouts/Handouts'),
        meta: {
            title: 'Handouts',
            showAd: true,
        },
    },
    {
        path: '/future-conferences',
        name: 'FutureConferences',
        beforeEnter : () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Conferences/FutureConferences'),
        meta: {
            title: 'Future Conferences',
            showAd: true,
        },
    },
]
