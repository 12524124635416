import { createStore } from 'vuex';

import user, { User } from './modules/user';
import fcm from './modules/fcm';
import conferences from './modules/conference';
import health from './modules/health';
import pageStore from './modules/page';
import timeslots from './modules/timeslots';
import sessions from './modules/sessions';
import directory from './modules/directory';
import question from './modules/question';
import global from './modules/global';
import appContent from './modules/app-content';

export interface StoreState {
  user: User,
}

const store = createStore({
    modules: {
        global: global,
        appContent: appContent,
        conferences: conferences,
        directory: directory,
        fcm: fcm,
        health: health,
        page: pageStore,
        question: question,
        sessions: sessions,
        timeslots: timeslots,
        user: user,
    },
    //plugins: [logrocketPlugin]
});

export default store;
