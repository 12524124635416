import SessionService from "@/services/session.service";
import moment from "moment-timezone";
import ConferenceService from "@/services/conference.service";
import UserService from "@/services/user.service";

const expiryMinutes = 10

export default {
    namespaced:true,
    state: {
        sessions: [],
        sessionsExpiry: null,
        featuredSession: null,
        featuredSessionExpiry: null,
        agenda: [],
        agendaExpiry: null,
    },
    mutations: {
        SET_SESSIONS(state, payload) {
            state.sessions = payload.sessions
            state.expiry = payload.expiry
        },
        SET_FEATURED_SESSION(state, payload) {
            state.featuredSession = payload.session
            state.featuredSessionExpiry = payload.expiry
        },
        RESET_SESSIONS(state) {
            state.sessions = []
            state.expiry = null
        },
        SET_AGENDA(state, payload) {
            state.agenda = payload.agenda
            state.agendaExpiry = payload.expiry
        },
        SET_MY_AGENDA_FOR_SESSION(state, payload) {
            const session_id = payload.session_id
            if (state.agenda.includes(session_id)) {
                state.agenda = state.agenda.filter(item => item !== session_id)
            } else {
                state.agenda.push(session_id)
            }
        }
    },
    getters: {
        getSessions: (state) => {
            return state.sessions
        },
        getFeaturedSession: (state) => {
            return state.featuredSession
        },
        getAgenda: (state) => {
            return state.agenda
        },
    },
    actions: {
        async fetchFeaturedSession({ commit }) {
            if (moment().isBefore(this.state.sessions.featuredSessionExpiry)) {
                return Promise.resolve(this.state.featuredSession)
            }

            return await ConferenceService.getFeaturedSession().then(response => {
                const session = response.data
                if (session == null) {
                    return;
                }
                const expiry = moment().add(expiryMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                commit('SET_FEATURED_SESSION', {session, expiry})
                return Promise.resolve(session)
            }).catch(error => {
                console.log(error.message);
                return Promise.reject(error)
            });
        },

        async fetchAgenda({ commit }, payload) {
            if (payload.showMessage) {
                commit('toggleLoading', { is_loading: true, message: 'Loading Agenda' }, { root: true })
            }

            if (moment().isBefore(this.state.sessions.agendaExpiry) && (payload.forceRefresh == null || !payload.forceRefresh)) {
                commit('toggleLoadingOffAbsolute', null, { root: true })
                return Promise.resolve(this.state.sessions)
            }

            return await UserService.getAgenda().then(response => {
                const agenda = response.data
                const expiry = moment().add(expiryMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                commit('SET_AGENDA', {agenda: agenda, expiry: expiry})
                return Promise.resolve(agenda)
            }).catch(error => {
                console.log(error.message);
                return Promise.reject(error)
            }).finally(() => {
                commit('toggleLoadingOffAbsolute', null, { root: true })
            });
        },

        async fetchSessions({ commit }, payload) {
            if (payload.showMessage) {
                commit('toggleLoading', { is_loading: true, message: 'Loading Session Details' }, { root: true })
            }

            if (moment().isBefore(this.state.sessions.expiry) && (payload.forceRefresh == null || !payload.forceRefresh)) {
                commit('toggleLoadingOffAbsolute', null, { root: true })
                return Promise.resolve(this.state.sessions)
            }

            return await SessionService.getActiveConferenceSessions()
                .then(response => {
                    const sessions = Object.values(response).reduce((a: Array<any>, b) => {
                        return a.concat(b);
                    }, []);
                    const expiry = moment().add(expiryMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                    commit('SET_SESSIONS', {sessions, expiry})
                    return Promise.resolve(sessions)
                }).catch(error => {
                    console.log(error.message);
                    return Promise.reject(error)
                }).finally(() => {
                    commit('toggleLoadingOffAbsolute', null, { root: true })
                });
        },
        async toggleMyAgendaForSession({ commit }, session_id) {
            commit('SET_NEXT_SESSION', null)
            commit('SET_MY_AGENDA_FOR_SESSION', {session_id: session_id})
        }
    },
};
