import { Capacitor } from '@capacitor/core';
import { loadView } from './index';
import { StatusBar, Style } from '@capacitor/status-bar';

const isStatusBarAvailable = Capacitor.isPluginAvailable('StatusBar') && Capacitor.getPlatform() === 'ios';
export default [
    {
        path: '/profile',
        name: 'Profile',
        beforeEnter : () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Profile/ProfileIndex'),
        meta: {
            title: 'My Profile',
            showAd: false,
        }
    }
]
