import asca_api from './asca_api';
import store from '../store';

class DelegateService {
    getActiveConferenceQuestion() {
        const userUuid = store.getters.get_user_profile.uuid;
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        return asca_api.get('v2/client/conference/' + activeConferenceId + '/delegate/getQuestion').then((response) => {
            return response
        });
    }
    submitAnswer(id, answer) {
        const userUuid = store.getters.get_user_profile.uuid;
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        return asca_api.post('v2/client/conference/' + activeConferenceId + '/delegate/storeAnswer/' + id, { answer: answer }).then((response) => {
            return response
        });
    }
}

export default new DelegateService();
