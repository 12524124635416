import UserService from "@/services/user.service";
import moment from "moment-timezone";
import store from "@/store";

const expiryMinutes = 10;
export default {
    namespaced: true,
    state: {
        conferences: [],
        conferencesExpiry: null,
        activeConference: null,
        activeConferenceExpiry: null,
        activeConferenceLeaderboard: null,
        activeConferenceLeaderboardExpiry: null,
    },
    mutations: {
        setConferences(state, payload) {
            state.conferences = payload.conferences;
            state.conferencesExpiry = payload.expiry;
        },
        setActiveConference(state, payload) {
            state.activeConference = payload.conference;
            state.activeConferenceExpiry = payload.expiry;

            moment.tz.setDefault(state.activeConference.timezone);
        },
        setActiveConferenceLeaderboard(state, payload) {
            state.activeConferenceLeaderboard = payload.conferenceLeaderboard;
            state.activeConferenceLeaderboardExpiry = payload.expiry;

            moment.tz.setDefault(state.activeConference.timezone);
        }
    },
    actions: {
        async getConferences({ commit }) {
            try {
                if (moment().isBefore(this.state.conferences.conferences)
                    && this.state.conferences.conferences.length > 0) {
                    return Promise.resolve(this.state.conferences.conferences);
                }
                UserService.getUserConferences().then((response) => {
                    const conferences = response.data;
                    const expiry = moment().add(expiryMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                    commit('setConferences', { conferences, expiry });
                    return Promise.resolve(conferences);
                });
            } catch (error) {
                console.log(error);
            }
        },

        async getActiveConference({ commit }) {
            try {
                if (moment().isBefore(this.state.conferences.activeConferenceExpiry) &&
                    this.state.conferences.activeConference) {
                    return Promise.resolve(this.state.conferences.activeConference);
                }

                return await UserService.getActiveConference().then((response) => {
                    const conference = response.data;
                    const expiry = moment().add(expiryMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss');
                    if (this.state.conferences.activeConference?.id !== conference.id) {
                        store.dispatch('sessions/fetchAgenda', { showMessage: false })

                        store.dispatch('timeslots/fetchTimeslotsSessions', { showMessage: false });
                    }
                    commit('setActiveConference', { conference, expiry });
                    return Promise.resolve(conference);
                });
            } catch (error) {
                console.log(error);
            }
        },

        async getActiveConferenceLeaderboard({ commit }) {
            try {
                if (moment().isBefore(this.state.conferences.activeConferenceLeaderboardExpiry) &&
                    this.state.conferences.activeConferenceLeaderboard) {
                    return Promise.resolve(this.state.conferences.activeConferenceLeaderboard);
                }

                return await UserService.getActiveConferenceLeaderboard().then((response) => {
                    const conferenceLeaderboard = response.data;
                    const expiry = moment().add(expiryMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss');
                    commit('setActiveConferenceLeaderboard', { conferenceLeaderboard, expiry });
                    return Promise.resolve(conferenceLeaderboard);
                });
            } catch (error) {
                console.log(error);
            }
        }
    },
    getters: {
        conferences(state) {
            return state.conferences;
        },
        activeConference(state) {
            return state.activeConference;
        }
    }
}
