import {Storage} from "@ionic/storage";

const storage = new Storage();
await storage.create();


//set state on refresh
const shownPermissionsModal = await storage.get('health.shownPermissionsModal') ?? false;

export default {
    namespaced: true,
    state: {
        shownPermissionsModal: shownPermissionsModal,
    },
    mutations: {
        setShownPermissionsModal(state, payload) {
            state.shownPermissionsModal = payload;
            storage.set('health.shownPermissionsModal', payload);
        },
    },
    actions: {
        setShownPermissionsModal({ commit }, payload) {
            commit('setShownPermissionsModal', payload);
        },
    },
}
