import { loadView } from './index';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

const isStatusBarAvailable = Capacitor.isPluginAvailable('StatusBar') && Capacitor.getPlatform() === 'ios';

export default [
    {
        path: '/introduction',
        name: 'Introduction',
        component: loadView('Slideshows/TutorialSlideshow'),
        meta: {
            title: 'Introduction'
        },
        beforeEnter: (to, from, next) => { //route entry only, same route calls handled by watcher in component
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }

            next()
        }
    }
]
