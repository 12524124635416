import { isPlatform } from '@ionic/core'

class HealthData {
    async isAuthorized () {
        if (!window.cordova) {
            return false
        }

        if (isPlatform('ios')) {
            return new Promise((resolve, reject) => {
                // iOS has a quirk where it will always return false when checking for read permissions (https://developer.apple.com/documentation/healthkit/hkhealthstore/1614154-authorizationstatusfortype?language=objc)
                // To get around this, rather than checking for read permissions, we execute a query for steps data for today and check if the result is not 0
                const startDate = new Date()
                startDate.setDate(startDate.getDate() - 7)
                startDate.setHours(0, 0, 0, 0)
                const endDate = new Date(new Date().setHours(23, 59, 59, 999))

                window.cordova.plugins.health.queryAggregated({
                    startDate: startDate,
                    endDate: endDate,
                    dataType: 'steps',
                    bucket: 'day',
                }, (data) => {
                    // check if data has a length and at least 1 of the array items has a value > 0
                    if (data.length && data.some((item) => item.value > 0)) {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                }, () => {
                    reject(false)
                })
            })
        }

        if (isPlatform('android')) {
            return new Promise((resolve, reject) => {
                window.cordova.plugins.health.isAuthorized({ read: ['steps'] }, (authorized) => {
                    resolve(authorized)
                }, () => {
                    reject(false)
                })
            })
        }
    }

    async getDailySteps (startDate, endDate) {
        if (!window.cordova) {
            return []
        }

        return new Promise((resolve, reject) => {
            window.cordova.plugins.health.queryAggregated(
                {
                    startDate: startDate,
                    endDate: endDate,
                    dataType: 'steps',
                    bucket: 'day',
                    filterOutUserInput: true,
                },
                (data) => {
                    resolve(data)
                },
                (err) => {
                    reject(err)
                },
            )
        })
    }

    async requestAuthorization () {
        return new Promise((resolve, reject) => {
            if (!window.cordova) {
                resolve(false)
            }

            window.cordova.plugins.health.requestAuthorization(
                {
                    read: ['steps'], //read only permission
                },
                () => {
                    resolve(true)
                },
                () => {
                    resolve(false)
                },
            )
        })
    }

    openHealthSettings() {
        if (!window.cordova) {
            return
        }

        window.cordova.plugins.health.openHealthSettings();
    }
}

export default new HealthData()
