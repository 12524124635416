<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Support</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div>
      <div class="p-4 my-4 bg-yellow-100 rounded-md">
        <div class="flex">
          <div class="flex-shrink-0">
            <QuestionMarkCircleIcon
              class="w-5 h-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3">
            <h3 class="mb-3 text-sm font-semibold text-yellow-800">
              Having trouble?
            </h3>
            <div class="mt-2 text-sm text-yellow-700">
              <div>
                <p class="mb-2">
                  We have a support site with answers to common questions and troubleshooting tips.
                </p>
                <div class="mt-6">
                  <div class="flex justify-end">
                    <button
                      type="button"
                      class="px-3 py-3 text-sm font-medium text-yellow-800 bg-yellow-200 rounded-md hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
                      @click="openExternalLink('https://help.ascaconferences.org')"
                    >
                      Visit Support Site
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="currentStep === 'category'"
    >
      <p>Please select the type of issue you're experiencing from the list below:</p>
      <div
        :class="supportRequest.category ? '' : 'min-h-[55vh]'"
      >
        <ion-select
          v-model="supportRequest.category"
          justify="space-between"
          label="Issue Type"
          :interface="'popover'"
        >
          <ion-select-option
            v-for="category in supportRequestCategories"
            :key="category.id"
            :value="category.id"
          >
            {{ category.name }}
          </ion-select-option>
        </ion-select>
      </div>
      <div
        v-if="helpText"
        class="bg-green-100 rounded my-4 py-2 px-4 space-y-2"
      >
        <h3 class="mt-4 text-md font-semibold">
          Please review the following information before submitting your request:
        </h3>
        <p v-html="helpText" />
        <div class="flex justify-end" />
      </div>
      <div class="flex justify-end">
        <ion-button
          color="secondary"
          :type="'button'"
          @click="closeModal"
        >
          Cancel
        </ion-button>
        <ion-button
          color="primary"
          :type="'button'"
          @click="currentStep = 'message'"
        >
          Next
        </ion-button>
      </div>
    </div>
    <div v-if="currentStep === 'message'">
      <p>Please provide your contact information and a description of your problem and a member of our support team will reach out to you.</p>
      <form
        class="relative"
        @submit.prevent="submitSupportRequest"
      >
        <div class="min-h-[50vh]">
          <ion-item>
            <ion-input
              v-model="supportRequest.name"
              :required="true"
              placeholder="Name"
            />
          </ion-item>
          <ion-item>
            <ion-input
              v-model="supportRequest.email"
              :required="true"
              :autocomplete="'email'"
              :inputmode="'email'"
              :type="'email'"
              placeholder="Email"
            />
          </ion-item>
          <div class="py-2 px-4">
            <label
              for="conference-select"
              class=""
            >Select your conference from the list below</label>
            <v-select
              v-model="supportRequest.requestable_id"
              name="conference-select"
              class="bg-white rounded-lg w-full"
              :options="conferences"
              :reduce="(conference) => conference.id"
              :clearable="false"
              :get-option-label="
                (conference) =>
                  conference.organization ? conference.organization.acronym + ' - ' + conference.name : conference.name
              "
            />
          </div>
          <ion-item>
            <ion-textarea
              v-model="supportRequest.message"
              required
              placeholder="Please provide some details about your request..."
              :rows="8"
            />
          </ion-item>
        </div>
        <div class="flex justify-end">
          <ion-button
            color="secondary"
            :type="'button'"
            @click="closeModal"
          >
            Cancel
          </ion-button>
          <ion-button
            color="primary"
            :type="'submit'"
            :disabled="isLoading"
          >
            Submit
          </ion-button>
        </div>
      </form>
    </div>
  </ion-content>
</template>

<script>
import {
    IonHeader,
    IonSelect,
    IonSelectOption,
    IonToolbar,
    IonTitle,
    IonContent,
    IonInput,
    IonItem,
    IonButton,
    IonTextarea,
    modalController, toastController,
} from '@ionic/vue'
import { defineComponent, ref } from 'vue'
import UserService from '@/services/user.service'
import SupportRequestService from '@/services/support-request.service'
import { QuestionMarkCircleIcon } from '@heroicons/vue/solid'
import { Browser } from '@capacitor/browser'
import ConferenceService from '@/services/conference.service'
import vSelect from 'vue-select'

export default defineComponent({
    name: "SupportModal",
    components: {
        vSelect,
        QuestionMarkCircleIcon,
        IonHeader,
        IonSelect,
        IonSelectOption,
        IonToolbar,
        IonTitle,
        IonContent,
        IonInput,
        IonTextarea,
        IonItem,
        IonButton,
    },
    setup() {
        const supportRequest = ref({
            name: '',
            email: '',
            message: '',
            category: '',
        })

        const supportRequestCategories = ref()

        const currentStep = ref('category')

        const conferences = ref()

        const isLoading = ref(false)

        return {
            supportRequest,
            supportRequestCategories,
            currentStep,
            conferences,
            isLoading,
        }
    },
    computed: {
        helpText() {
            if (!this.supportRequest.category) return null
            if (!this.supportRequestCategories) return null

            return this.supportRequestCategories.find((category) => category.id === this.supportRequest.category).help_text
        }
    },
    mounted () {
        SupportRequestService.getSupportRequestCategories().then((response) => {
            this.supportRequestCategories = response.data
        });
        if (this.$store.state.user.is_authenticated) {
            UserService.getUserData().then((response) => {
                this.supportRequest.name = response.data.first_name + ' ' + response.data.last_name
                this.supportRequest.email = response.data.email
                this.supportRequest.requestable_type = "App\\Models\\Conference"
                this.supportRequest.requestable_id = response.data.active_conference_id
                this.supportRequest.device = this.$store.state.user.device
            });
        }
        ConferenceService.getAllConferences().then((response) => {
            this.conferences = response.data
        });
    },
    methods: {
        closeModal() {
            modalController.dismiss();
        },
        submitSupportRequest() {
            this.isLoading = true
            SupportRequestService.createSupportRequest(this.supportRequest).then(() => {
                this.closeModal();
                this.showToast('success', 'Your support request has been submitted. A member of our team will reach out to you shortly.');
                this.isLoading = false
            });
        },
        async showToast(type, message) {
            const toast = await toastController.create({
                color: type,
                message: message,
                keyboardClose: true,
                position: 'bottom',
                duration: 3333,
                buttons: [{
                    text: 'Close',
                    role: 'cancel',
                }
                ],
                cssClass: 'text-md font-medium text-white',

            })

            await toast.present();
        },
        async openExternalLink (url) {
            await Browser.open({ url: url });
        },
    }
});
</script>
