import router from '@/router'
import { Module } from 'vuex'
import AuthService from './../../services/auth.service'
import asca_api from './../../services/asca_api';
import { FCM } from "@capacitor-community/fcm";
import {Capacitor} from "@capacitor/core";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {Storage} from "@ionic/storage";

const storage = new Storage();
await storage.create();


//set state on refresh
const userToken = await storage.get('token') ?? null
const userIsAuthenticated = await storage.get('is_authenticated') === 'true' ?? false //TODO: let's check this via api in future
const userData = (JSON.parse(await storage.get('user')) && JSON.parse(await storage.get('user')).data) ? JSON.parse(await storage.get('user')).data : JSON.parse(await storage.get('user'))
const userDevice = await storage.get('device_name')

export interface User {
    active_conference_id: number | null;
    avatar_url: string | null;
    conferences: any[] | null;
    created_at: string | null;
    deleted_at: string | null;
    email: string | null;
    email_verified_at: string | null;
    first_name: string | null;
    full_name: string | null;
    last_login_at: string | null;
    last_name: string | null;
    media: any[] | null;
    meetingplay_initial_password: string | null;
    prefix: string | null;
    profile: object | null;
    roles: any[] | null;
    shown_session_swipe_tutorial: boolean | null;
    speaker_id: number | null;
    suffix: string | null;
    updated_at: string | null;
    uuid: string;
}

const defaultState: User = {
    active_conference_id: null,
    avatar_url: null,
    conferences: null,
    created_at: null,
    deleted_at: null,
    email: null,
    email_verified_at: null,
    first_name: null,
    full_name: null,
    last_login_at: null,
    last_name: null,
    media: null,
    meetingplay_initial_password: null,
    prefix: null,
    profile: null,
    roles: null,
    shown_session_swipe_tutorial: null,
    speaker_id: null,
    suffix: null,
    updated_at: null,
    uuid: null,
};

export interface UserState {
    is_authenticated: boolean;
    token: string;
    data: User;
    device: string;
}

const defaultUserState: UserState = {
    is_authenticated: userIsAuthenticated,
    token: userToken,
    data: userData,
    device: userDevice,
};

const userStore: Module<UserState, any> = {
    // namespaced: true,
    state: defaultUserState,
    actions: {
        /**
       * Endpoint: /client/conference/1/action/session/
       * Toggle whether session is on user's agenda
       */
        async saveAvatar({ commit }, payload) {
            commit('toggleLoading', { is_loading: true, message: 'Saving Avatar' }, { root: true })

            return await asca_api.post('/client/me/profile/avatar', payload,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    commit('SET_USER_AVATAR', response.data.data.avatar_url)

                    return Promise.resolve(response)
                }).catch(error => {
                    return Promise.reject(error)
                }).finally(() => {
                    commit('toggleLoadingOff', null, { root: true })
                });
        },
        //TODO: auth things, put in auth service
        validateEmail({ commit }, userData) {
            return AuthService.validateEmail(userData)
                .then(response => {
                    if (response.data.data.vertification_needed) {
                        router.replace({
                            name: 'FirstTimeLogin',
                            params: { userData: JSON.stringify(userData) },
                            query: { email: userData.email }
                        })
                    }
                    return Promise.resolve(response)
                }, error => {
                    commit('loginFailure')
                    return Promise.reject(error)
                })
        },
        async forgotPassword({ commit }, payload) {
            commit('toggleLoading', { is_loading: true, message: 'Resetting Password' }, { root: true })

            return await asca_api.post('/auth/forgot-password', payload)
                .then(response => {
                    if (response.data.data.vertification_needed) {
                        router.replace({
                            name: 'ForgotPassword',
                            params: { userData: JSON.stringify(response.data.data) },
                            query: { email: response.data.data.email }
                        })
                    }
                    return Promise.resolve(response)
                }).catch(error => {
                    return Promise.reject(error)
                }).finally(() => {
                    commit('toggleLoadingOff', null, { root: true })
                });
        },
        validateVerificationCode({ commit }, userData) {
            const isFCMAvailable = Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('FCM');
            return AuthService.validateVerificationCode(userData)
                .then(async response => {

                    const responseData = response.data.data;
                    const token = responseData.token ?? null;
                    const userData = responseData.user ?? {};

                    if (token) {
                        localStorage.setItem('token', responseData.token)
                        await storage.set('token', responseData.token)
                        await storage.set('user', JSON.stringify(userData))
                        await storage.set('is_authenticated', 'true')

                        if (isFCMAvailable) {
                            if (userData.conferences && userData.conferences.length && userData.conferences[0].pivot) {
                                if (userData.conferences[0].pivot.is_virtual)
                                    FCM.subscribeTo({topic: 'virtual-only'})
                                else
                                    FCM.subscribeTo({topic: 'in-person'})
                            }
                        }

                        commit('loginSuccess', responseData)

                        router.replace('/dashboard').then(r => {
                            return Promise.resolve(response);
                        })
                    }

                    return Promise.resolve(response)
                }, error => {
                    commit('loginFailure')
                    return Promise.reject(error)
                })
        },
        resetPassword({ commit }, userData) {
            const isFCMAvailable = Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('FCM');
            return AuthService.resetPassword(userData)
                .then(async response => {

                    const responseData = response.data.data;
                    const token = responseData.token ?? null;
                    const userData = responseData.user ?? {};

                    if (token) {
                        localStorage.setItem('token', responseData.token)
                        await storage.set('token', responseData.token)
                        await storage.set('user', JSON.stringify(userData))
                        await storage.set('is_authenticated', 'true')

                        if (isFCMAvailable) {
                            if (userData.conferences && userData.conferences.length && userData.conferences[0].pivot) {
                                if (userData.conferences[0].pivot.is_virtual)
                                    FCM.subscribeTo({topic: 'virtual-only'})
                                else
                                    FCM.subscribeTo({topic: 'in-person'})
                            }
                        }

                        commit('loginSuccess', responseData)

                        router.replace('/dashboard').then(r => {
                            return Promise.resolve(response);
                        })
                    }

                    return Promise.resolve(response)
                }, error => {
                    commit('loginFailure')
                    return Promise.reject(error)
                })
        },
        logIn({ commit }, userData) {
            return AuthService.login(userData)
                .then(async response => {
                    const responseData = response.data.data;
                    const token = responseData.token ?? null;
                    const userData = responseData.user ?? {};

                    await FirebaseAnalytics.setUserId({
                        userId: userData.uuid,
                    });

                    if (token) {
                        localStorage.setItem('token', responseData.token)
                        await storage.set('token', responseData.token)
                        await storage.set('user', JSON.stringify(userData))
                        await storage.set('is_authenticated', 'true')
                    }

                    if (userData.conferences.length > 0) {
                        commit('loginSuccess', responseData)

                        if (router.currentRoute.value.query && router.currentRoute.value.query.from) {
                            router.replace(router.currentRoute.value.query.from.toString()).then(r => {
                                return Promise.resolve(response);
                            });
                        } else {
                            router.replace('/dashboard').then(r => {
                                return Promise.resolve(response);
                            });
                        }
                    } else {
                        commit('loginFailure')
                        return Promise.reject('You aren\'t registered for any conferences yet!')
                    }
                }, error => {
                    commit('loginFailure')
                    return Promise.reject(error)
                })
        },

        logOut() {
            return AuthService.logout()
                .then(() => {
                    router.replace('/login').then(() => {
                        location.reload();
                    });
                }, error => {
                    router.replace('/login').then(() => {
                        location.reload();
                    });
                    return Promise.reject(error)
                })
        },
        /**
       * Endpoint: /client/me/profile/
       * Save User profile
       */
        async saveUserProfile({ commit }, payload) {
            commit('toggleLoading', { is_loading: true, message: 'Saving profile' }, { root: true })

            return await asca_api.post('/client/me/profile/', { payload: payload.user_data })
                .then(async response => {
                    commit('updateUser', response.data.data.user)
                    await storage.set('user', JSON.stringify(response.data.data.user))
                    return Promise.resolve(response)
                }).catch(error => {
                    return Promise.reject(error)
                }).finally(() => {
                    commit('toggleLoadingOff', null, { root: true })
                });
        },

        async setActiveConference({ commit }, payload) {
            commit('toggleLoading', { is_loading: true, message: 'Setting active conference' }, { root: true })

            return await asca_api.post('/v2/client/user/active-conference/', { conference_id: payload, userData: JSON.parse(await storage.get('user')) })
                .then(async response => {
                    commit('updateUser', response.data.data.user)
                    await storage.set('user', JSON.stringify(response.data.data.user))
                    return Promise.resolve(response)
                }).catch(error => {
                    return Promise.reject(error)
                }).finally(() => {
                    commit('toggleLoadingOff', null, { root: true })
                });

        }
    },
    getters: {
        get_avatar_url(state) { return state.data.avatar_url },
        is_authenticated: (state) => () => state.is_authenticated,
        is_virtual(state) {
            const activeConference = state.data.conferences?.filter(conference => conference.id == state.data.active_conference_id)[0]
            return activeConference?.pivot?.is_virtual ?? false
        },
        is_delegate(state) {
            const activeConference = state.data.conferences?.filter(conference => conference.id == state.data.active_conference_id)[0]
            return activeConference?.pivot?.is_delegate ?? false
        },
        is_ambassador(state) {
            const activeConference = state.data.conferences?.filter(conference => conference.id == state.data.active_conference_id)[0]
            return activeConference?.pivot?.is_ambassador ?? false
        },
        get_user_device(state) {
            return state.device
        },
        get_user_email(state) {
            return state.data.email
        },
        get_user_profile(state) {
            return (state.data) ?? []
        },
        get_user_conferences(state) {
            return state.data.conferences ?? []
        },
        get_active_conference(state) {
            return state["conferences/activeConference"] ?? []
        },
        get_active_conference_points(state) {
            return state.data.conferences?.filter(conference => conference.id == state.data.active_conference_id)[0]?.pivot?.points ?? 0
        }
    },
    mutations: {
        /**
       * Store new avatar url
       */
        SET_USER_AVATAR(state, url) {
            state.data.avatar_url = url
        },
        logOut(state) {
            state.is_authenticated = false;
            state.token = null;
            state.data = defaultState;
        },

        loginSuccess(state, payload) {
            state.is_authenticated = true;
            state.token = payload.token
            state.data = payload.user;
        },

        updateUser(state, payload) {
            state.data = payload;
        },

        loginFailure(state) {
            // state = defaultUserState;
            state.is_authenticated = false;
            state.token = null;
            state.data = defaultState;
        },
    },
};

export default userStore;
