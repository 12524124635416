<template>
  <ion-menu
    side="end"
    type="overlay"
    menu-id="main"
    class="overlay-menu"
    content-id="main-content"
    :style="
      primaryColor
        ? { '--background': primaryColor }
        : { '--background': '#643695' }
    "
  >
    <div
      class="relative w-full min-h-screen bg-center bg-no-repeat bg-cover ion-page"
      :style="menuStyle"
    >
      <ion-header
        class="ion-no-border p-3 pb-0"
      >
        <ion-toolbar class="ion-text-center ion-no-border">
          <ion-buttons slot="start">
            <img
              class="relative object-contain w-full mx-auto max-h-tb-icon"
              :src="logo"
              alt="ASCA Logo"
              title="ASCA Logo"
            >
          </ion-buttons>

          <ion-buttons slot="end">
            <ion-button
              fill="clear"
              @click="clickClose()"
            >
              <XIcon
                class="w-6 h-6"
                :class="[
                  isCurrentRoute('/sessions') ? activeChildClass : 'text-white',
                ]"
              />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content
        :fullscreen="true"
        :translucent="true"
        :scroll-events="true"
      >
        <ion-grid class="relative w-full h-full ion-text-left">
          <ion-row v-if="userFirstName">
            <ion-col>
              <div
                id="userGreeting"
                class="relative flex items-center mt-2 mb-6 space-x-4"
              >
                <div class="relative">
                  <img
                    class="relative object-contain h-12 rounded-full"
                    :src="
                      userAvatar
                        ? userAvatar
                        : 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y'
                    "
                    alt=""
                    title=""
                  >
                </div>
                <span class="relative text-white font-sentient text-h3">Hi, {{ userFirstName }}</span>
              </div>
            </ion-col>
          </ion-row>

          <ion-row v-if="conferences && selected">
            <ion-col>
              <label
                for="conference-select"
                class="text-white"
              >Active Conference</label>
              <v-select
                v-model="selected.conference"
                name="conference-select"
                class="bg-white rounded-lg"
                :options="conferences"
                :reduce="(conference) => conference.id"
                :clearable="false"
                :get-option-label="
                  (conference) =>
                    conference.organization ? conference.organization.acronym + ' - ' + conference.name : conference.name
                "
              />
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="4">
              <router-link
                to="/dashboard"
                class="relative block w-full h-full rounded-2xl"
                :class="[
                  isCurrentRoute('/dashboard')
                    ? activeParentClass
                    : inactiveParentClass,
                ]"
                v-on="{
                  click: isCurrentRoute('/dashboard') ? clickClose : null,
                }"
              >
                <div
                  class="relative flex flex-col w-full p-3 space-y-2.5 text-left"
                >
                  <HomeIcon
                    class="w-6 h-6 mb-2"
                    :class="[
                      isCurrentRoute('/dashboard')
                        ? activeChildClass
                        : 'text-purple',
                    ]"
                  />
                  <h5
                    class="relative text-base font-medium capitalize break-normal text-header-text"
                  >
                    Dashboard
                  </h5>
                </div>
              </router-link>
            </ion-col>

            <ion-col size="4">
              <router-link
                :to="{
                  name: 'Sessions',
                  query: {
                    all_sessions: false,
                  },
                }"
                class="relative block w-full h-full rounded-2xl"
                :class="[
                  isCurrentRoute('/sessions')
                    ? activeParentClass
                    : inactiveParentClass,
                ]"
                v-on="{
                  click: isCurrentRoute('/sessions') ? clickClose : null,
                }"
              >
                <div
                  class="relative flex flex-col w-full p-3 space-y-2.5 text-left"
                >
                  <CalendarIcon
                    class="w-6 h-6 mb-2"
                    :class="[
                      isCurrentRoute('/sessions')
                        ? activeChildClass
                        : 'text-cyan',
                    ]"
                  />
                  <h5
                    class="relative text-base font-medium capitalize text-header-text"
                  >
                    My Agenda
                  </h5>
                </div>
              </router-link>
            </ion-col>

            <ion-col size="4">
              <router-link
                :to="{
                  name: 'Sessions',
                  query: {
                    all_sessions: true,
                  },
                }"
                class="relative block w-full h-full rounded-2xl"
                :class="[
                  isCurrentRoute('/sessions')
                    ? activeParentClass
                    : inactiveParentClass,
                ]"
                v-on="{
                  click: isCurrentRoute('/sessions') ? clickClose : null,
                }"
              >
                <div
                  class="relative flex flex-col w-full p-3 space-y-2.5 text-left"
                >
                  <CalendarIcon
                    class="w-6 h-6 mb-2"
                    :class="[
                      isCurrentRoute('/sessions')
                        ? activeChildClass
                        : 'text-cyan',
                    ]"
                  />
                  <h5
                    class="relative text-base font-medium capitalize text-header-text"
                  >
                    All Sessions
                  </h5>
                </div>
              </router-link>
            </ion-col>

            <ion-col size="4">
              <router-link
                to="/directory"
                class="relative block w-full h-full rounded-2xl"
                :class="[
                  isCurrentRoute('/directory')
                    ? activeParentClass
                    : inactiveParentClass,
                ]"
                v-on="{
                  click: isCurrentRoute('/directory') ? clickClose : null,
                }"
              >
                <div
                  class="relative flex flex-col w-full p-3 space-y-2.5 text-left"
                >
                  <UserGroupIcon
                    class="w-6 h-6 mb-2"
                    :class="[
                      isCurrentRoute('/directory')
                        ? activeChildClass
                        : 'text-yellow',
                    ]"
                  />
                  <h5
                    class="relative text-base font-medium capitalize text-header-text"
                  >
                    Directory
                  </h5>
                </div>
              </router-link>
            </ion-col>

            <ion-col
              v-if="activeConference?.sponsor_count > 0"
              size="4"
            >
              <router-link
                to="/sponsors"
                class="relative block w-full h-full rounded-2xl"
                :class="[
                  isCurrentRoute('/sponsors')
                    ? activeParentClass
                    : inactiveParentClass,
                ]"
                v-on="{
                  click: isCurrentRoute('/sponsors') ? clickClose : null,
                }"
              >
                <div
                  class="relative flex flex-col w-full p-3 space-y-2.5 text-left"
                >
                  <BadgeCheckIcon
                    class="w-6 h-6 mb-2"
                    :class="[
                      isCurrentRoute('/sponsors')
                        ? activeChildClass
                        : 'text-cyan',
                    ]"
                  />
                  <h5
                    class="relative text-base font-medium capitalize text-header-text"
                  >
                    Sponsors
                  </h5>
                </div>
              </router-link>
            </ion-col>

            <ion-col
              v-if="activeConference?.exhibitor_count > 0"
              size="4"
            >
              <router-link
                to="/exhibitors"
                class="relative block w-full h-full rounded-2xl"
                :class="[
                  isCurrentRoute('/exhibitors')
                    ? activeParentClass
                    : inactiveParentClass,
                ]"
                v-on="{
                  click: isCurrentRoute('/exhibitors') ? clickClose : null,
                }"
              >
                <div
                  class="relative flex flex-col w-full p-3 space-y-2.5 text-left"
                >
                  <PresentationChartBarIcon
                    class="w-6 h-6 mb-2"
                    :class="[
                      isCurrentRoute('/exhibitors')
                        ? activeChildClass
                        : 'text-purple',
                    ]"
                  />
                  <h5
                    class="relative text-base font-medium capitalize text-header-text"
                  >
                    Exhibitors
                  </h5>
                </div>
              </router-link>
            </ion-col>

            <ion-col size="4">
              <router-link
                to="/maps"
                class="relative block w-full h-full cursor-pointer rounded-2xl"
                :class="[isCurrentRoute('/maps') ? activeParentClass : inactiveParentClass]"
                v-on="{ click: (isCurrentRoute('/maps')) ? clickClose : null }"
              >
                <div class="relative flex flex-col w-full p-3 space-y-2.5 text-left">
                  <MapIcon
                    class="w-6 h-6 mb-2"
                    :class="[isCurrentRoute('/maps') ? activeChildClass : 'text-green-400']"
                  />
                  <h5 class="relative text-base font-medium capitalize text-header-text">
                    Maps
                  </h5>
                </div>
              </router-link>
            </ion-col>

            <ion-col size="4">
              <router-link
                to="/notifications"
                class="relative block w-full h-full rounded-2xl"
                :class="[
                  isCurrentRoute('/notifications')
                    ? activeParentClass
                    : inactiveParentClass,
                ]"
                v-on="{
                  click: isCurrentRoute('/notifications') ? clickClose : null,
                }"
              >
                <div
                  class="relative flex flex-col w-full p-3 space-y-2.5 text-left"
                >
                  <BellIcon
                    class="w-6 h-6 mb-2"
                    :class="[
                      isCurrentRoute('/notifications')
                        ? activeChildClass
                        : 'text-red',
                    ]"
                  />
                  <h5
                    class="relative text-base font-medium capitalize text-header-text"
                  >
                    Alerts
                  </h5>
                </div>
              </router-link>
            </ion-col>

            <ion-col size="4">
              <div
                class="relative block w-full h-full text-gray-700 bg-white border-2 border-white rounded-2xl"
                @click="openModal"
              >
                <div
                  class="relative flex flex-col w-full p-3 space-y-2.5 text-left"
                >
                  <SupportIcon
                    class="w-6 h-6 mb-2"
                    :class="'text-purple'"
                  />
                  <h5
                    class="relative text-base font-medium capitalize text-header-text"
                  >
                    Support
                  </h5>
                </div>
              </div>
            </ion-col>

            <ion-col size="4">
              <router-link
                to="/information"
                class="relative block w-full h-full rounded-2xl"
                :class="[
                  isCurrentRoute('/information')
                    ? activeParentClass
                    : inactiveParentClass,
                ]"
                v-on="{
                  click: isCurrentRoute('/information') ? clickClose : null,
                }"
              >
                <div
                  class="relative flex flex-col w-full p-3 space-y-2.5 text-left"
                >
                  <InformationCircleIcon
                    class="w-6 h-6 mb-2"
                    :class="[
                      isCurrentRoute('/notifications')
                        ? activeChildClass
                        : 'text-cyan',
                    ]"
                  />
                  <h5
                    class="relative text-base font-medium capitalize text-header-text"
                  >
                    Info
                  </h5>
                </div>
              </router-link>
            </ion-col>

            <ion-col size="4">
              <router-link
                to="/profile"
                class="relative block w-full h-full rounded-2xl"
                :class="[
                  isCurrentRoute('/profile')
                    ? activeParentClass
                    : inactiveParentClass,
                ]"
                v-on="{ click: isCurrentRoute('/profile') ? clickClose : null }"
              >
                <div
                  class="relative flex flex-col w-full p-3 space-y-2.5 text-left"
                >
                  <UserIcon
                    class="w-6 h-6 mb-2"
                    :class="[
                      isCurrentRoute('/profile')
                        ? activeChildClass
                        : 'text-yellow',
                    ]"
                  />
                  <h5
                    class="relative text-base font-medium capitalize text-header-text"
                  >
                    My Profile
                  </h5>
                </div>
              </router-link>
            </ion-col>

            <ion-col size="4">
              <a
                class="relative block w-full h-full cursor-pointer rounded-2xl"
                :class="inactiveParentClass"
                @click="logout()"
              >
                <div
                  class="relative flex flex-col w-full p-3 space-y-2.5 text-left"
                >
                  <LogoutIcon
                    class="w-6 h-6 mb-2"
                    :class="'text-purple'"
                  />
                  <h5
                    class="relative text-base font-medium capitalize text-header-text"
                  >
                    Logout
                  </h5>
                </div>
              </a>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </div>
  </ion-menu>
</template>


<script lang="ts">
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonMenu,
    IonRow,
    IonToolbar,
    menuController,
    modalController,
} from "@ionic/vue";
import {
    BadgeCheckIcon,
    BellIcon,
    CalendarIcon,
    HomeIcon,
    InformationCircleIcon,
    LogoutIcon,
    MapIcon,
    PresentationChartBarIcon,
    SupportIcon,
    UserGroupIcon,
    UserIcon,
    XIcon,
} from "@heroicons/vue/outline";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import SupportModal from "@/components/SupportModal.vue";
import ConferenceService from "@/services/conference.service";
import UserService from "@/services/user.service";
import {mapState, useStore} from "vuex";

export default defineComponent({
    name: "AppMenu",
    components: {
        IonContent,
        IonHeader,
        IonMenu,
        IonToolbar,
        IonCol,
        IonGrid,
        IonRow,
        IonButtons,
        IonButton,
        HomeIcon,
        CalendarIcon,
        UserIcon,
        BellIcon,
        BadgeCheckIcon,
        InformationCircleIcon,
        PresentationChartBarIcon,
        UserGroupIcon,
        MapIcon,
        XIcon,
        LogoutIcon,
        SupportIcon,
        vSelect,
    },
    props: {
        logo: {
            type: String,
            default: null,
        },
        menuStyle: {
            type: String,
            default: null,
        },
        primaryColor: {
            type: String,
            default: null,
        },
        conferenceName: {
            type: String,
            default: null,
        },
    },
    setup() {
        const store = useStore();
        return {
            store,
        };
    },
    data() {
        return {
            activeParentClass:
        "bg-[" +
        this.primaryColor +
        "] text-white opacity-90 border-2 border-green-50",
            inactiveParentClass: "bg-white text-gray-700 border-2 border-white",
            activeChildClass: "text-white",
            inactiveChildClass: "bg-white text-gray-700",
            selected: {
                conference: "",
            },
        };
    },
    computed: {
        userFirstName() {
            return this.$store.state.user.data?.first_name ?? null;
        },
        userAvatar() {
            return this.$store.state.user.data?.avatar_url ?? null;
        },
        ...mapState('conferences', [
            'conferences',
            'activeConference',
        ])
    },
    watch: {
        selected: {
            handler: function (val) {
                if (
                    val.conference !== this.$store.state.user.data?.active_conference_id
                ) {
                    this.changeConference();
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.store.dispatch('conferences/getActiveConference').then((conference) => {
            this.selected.conference = conference.id;
        });
        this.store.dispatch("conferences/getConferences");
    },
    methods: {
        logout() {
            this.$store.commit("toggleLoading", {
                is_loading: true,
                message: "Logging out",
            });
            this.$store.dispatch("logOut");
        },
        clickAlert(string) {
            alert(string);
        },
        isCurrentRoute(path: string) {
            const route = useRoute();

            return route.path === path;
        },
        navigate(url: string) {
            this.$store.commit("toggleLoading", {
                is_loading: true,
                message: "Loading Page",
            });
            menuController.close("main").then((response) => {
                this.$router.push(url);
                this.$store.commit("toggleLoadingOff");
                return Promise.resolve(response);
            });
        },
        clickClose() {
            menuController.close("main");
        },
        changeConference() {
            this.$store.commit("toggleLoading", {
                is_loading: true,
                message: "Loading Page",
            });
            this.$store
                .dispatch("setActiveConference", this.selected.conference)
                .then(() => {
                    this.$router
                        .push({ path: '/dashboard' })
                        .then(() => {
                            this.$router.go();
                        });
                });
            this.$store.commit("toggleLoadingOff");
        },
        async openModal() {
            const modal = await modalController.create({
                component: SupportModal,
            });
            return modal.present();
        },
    },
});
</script>


<style scoped>
.overlay-menu {
  /*--background: linear-gradient(93.59deg, #502B77 17.59%, #643695 86.1%);*/
  --width: 100%;
  --min-width: 100%;
}

ion-content {
  --ion-background-color: translucent;
}

/* .ion-color-purple { --ion-color-base: #502B77 !important; } */
</style>
<style>
.menu-content-open {
  pointer-events: auto !important;
}

.vs__dropdown-toggle {
  padding: 0.5rem 0.25rem;
}
.vs__dropdown-option {
  padding: 0.5rem 0.25rem;
}
.vs__dropdown-menu {
  border-radius: 4px;
}
</style>
