<template>
  <div />
</template>
<script lang="ts">
import {BackgroundFetch} from "@transistorsoft/capacitor-background-fetch";
import ConferenceService from "@/services/conference.service";
import {defineComponent, ref} from "vue";
import HealthData from "@/helpers/health.data"
import {Capacitor} from "@capacitor/core";
import {mapState} from "vuex";
import moment from "moment-timezone";

export default defineComponent({
    name: "BackgroundTasks",
    components: {},
    setup() {
        const healthData = ref(); // holds the health (steps) data from the device

        return {
            healthData,
        }
    },
    computed: {
        dateRange() {
            const momentStartDate = moment(this.activeConference.start_at).tz(this.activeConference.timezone);
            const momentEndDate = moment(this.activeConference.end_at).subtract(2, 'days').tz(this.activeConference.timezone);

            const startDate = new Date(momentStartDate.year(), momentStartDate.month(), momentStartDate.date());
            const endDate = new Date(momentEndDate.year(), momentEndDate.month(), momentEndDate.date(), 23, 59, 59);

            return {
                start: startDate,
                end: endDate
            }
        },
        totalSteps() {
            let total = 0;
            if (this.healthData) {
                this.healthData.forEach((day) => {
                    total += day.value;
                });
            }
            total = Math.round(total);
            return total;
        },
        ...mapState('conferences', ['activeConference'])
    },
    async mounted() {
        if (Capacitor.isNativePlatform()) {
            await this.initBackgroundFetch();
        }
    },
    methods: {
        // initialize & configure the background task(s)
        async initBackgroundFetch() {
            const status = await BackgroundFetch.configure({
                minimumFetchInterval: 15 // <-- minutes (15 is minimum allowed)
            }, async (taskId) => {
                // Perform background work in an awaited Promise
                await this.backgroundRunner();

                // [REQUIRED] Signal to the OS that your work is complete.
                await BackgroundFetch.finish(taskId);
            }, async (taskId) => {
                // The OS has signalled that the remaining background-time has expired.
                // We must immediately complete out work and signal #finish.
                console.log('[Background Task] TIMEOUT:', taskId);
                // [REQUIRED] Signal to the OS that your work is complete.
                await BackgroundFetch.finish(taskId);
            });

            // Checking BackgroundFetch status:
            if (status !== BackgroundFetch.STATUS_AVAILABLE) {
                // Uh-oh:  we have a problem:
                if (status === BackgroundFetch.STATUS_DENIED) {
                    alert('The user explicitly disabled background behavior for this app or for the whole system.');
                } else if (status === BackgroundFetch.STATUS_RESTRICTED) {
                    alert('Background updates are unavailable and the user cannot enable them again.')
                }
            }
        },

        async backgroundRunner() {
            return new Promise((resolve, reject) => {
                try {
                    // Background Job 1: Ensure we have a valid FCM token
                    const isPushNotificationsAvailable = Capacitor.isPluginAvailable("PushNotifications");

                    if (isPushNotificationsAvailable) {
                        this.$store.dispatch('fcm/registerPushNotifications');
                    }

                    // Background Job 2: Sync the health data from the device to the server
                    HealthData.getDailySteps(this.dateRange.start, this.dateRange.end).then((healthData: any) => {
                        this.healthData = healthData;
                        ConferenceService.postSteps(this.totalSteps).then((res) => {
                            resolve(res);
                        }).catch((err) => {
                            reject(err);
                        });
                    });
                }
                catch (e) {
                    reject(e);
                }
            });
        }
    }
})

</script>
