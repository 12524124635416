import axios from 'axios'

//axios config
export default axios.create({
    timeout: 30000,
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'X-Authorization': process.env.VUE_APP_API_APP_TOKEN
    }
});
