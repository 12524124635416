import asca_api from './asca_api';
import store from '../store';

class SessionService {
    getActiveConferenceSessions() {
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        return asca_api.get('v2/client/conference/' + activeConferenceId + '/sessions?exclude-agenda').then((response) => {
            return response.data
        });
    }

    toggleAgenda(sessionId) {
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        return asca_api.post('v2/client/conference/' + activeConferenceId + '/action/session/' + sessionId).then((response) => {
            return response.data
        });
    }

    getSession(sessionId) {
        return asca_api.get('v2/client/session/' + sessionId).then((response) => {
            return response.data
        });
    }
    getEvaluation(sessionId) {
        const userUuid = store.getters.get_user_profile.uuid;
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        return asca_api.get('v2/client/conference/' + activeConferenceId + '/session/' + sessionId + '/eval').then((response) => {
            return response.data
        });
    }

    submitEvaluation(sessionId, evaluation) {
        const userUuid = store.getters.get_user_profile.uuid;
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        return asca_api.post('v2/client/conference/' + activeConferenceId + '/action/eval/' + sessionId, { eval: evaluation }).then((response) => {
            return response.data
        });
    }
}

export default new SessionService();
