import asca_api from '../../services/asca_api';
export default {
    namespaced:true,
    state: {
        sponsors: [],
        exhibitors: [],
        attendees: [],
    },
    mutations: {
        /**
       * Store directory
       */
        SET_DIRECTORY(state, directory) {
            state.directory = directory
        },
        SET_SPONSORS(state, sponsors) {
            state.sponsors = sponsors
        },
        SET_EXHIBITORS(state, exhibitors) {
            state.exhibitors = exhibitors
        },
        SET_ATTENDEES(state, attendees) {
            state.attendees = attendees
        },
        /**
       * RESET directory
       */
        RESET_DIRECTORY(state) {
            state.directory = []
        },
    },
    getters: {
        get: (state) => state.directory,
        getSponsors: (state) => state.sponsors,
        getExhibitors: (state) => state.exhibitors,
        getAttendees: (state) => state.attendees,
        getSponsorById: (state) => (id) => {
            return state.sponsors?.find((sponsor) => sponsor.id == id)
        },
        getExhibitorById: (state) => (id) => {
            const copy = [ ...state.exhibitors]
            if(typeof copy !== 'undefined')
                return copy.find(exhibitor => exhibitor.id == id);
            else return false
        },
        getAttendeeById: (state) => (id) => {
            const copy = [ ...state.attendees]
            if(typeof copy !== 'undefined')
                return copy.find(attendee => attendee.id == id);
            else return false
        }
    },
    actions: {
        /**
       * Endpoint: /client/conference/1/directory
       * Get all directory
       */
        async fetch({ commit }) {
            commit('toggleLoading', { is_loading: true, message: 'Loading Directory' }, { root: true })

            return await asca_api.get('/client/conference/1/directory')
                .then(response => {
                    commit('SET_DIRECTORY', response.data)

                    return Promise.resolve(response)
                }).catch(error => {
                    return Promise.reject(error)
                }).finally(() => {
                    commit('toggleLoadingOff', null, { root: true })
                });
        },
        /**
       * Endpoint: /client/conference/1/sponsors
       * Get all sponsors
       */
        async fetchSponsors({ commit }) {
            commit('toggleLoading', { is_loading: true, message: 'Loading Sponsors' }, { root: true })

            return await asca_api.get('/client/conference/1/sponsors')
                .then(response => {
                    commit('SET_SPONSORS', response.data.data)

                    return Promise.resolve(response)
                }).catch(error => {
                    return Promise.reject(error)
                }).finally(() => {
                    commit('toggleLoadingOff', null, { root: true })
                });
        },
        /**
       * Endpoint: /client/conference/1/exhibitors
       * Get all exhibitors
       */
        async fetchExhibitors({ commit }) {
            commit('toggleLoading', { is_loading: true, message: 'Loading Exhibitors' }, { root: true })

            return await asca_api.get('/client/conference/1/exhibitors')
                .then(response => {
                    commit('SET_EXHIBITORS', response.data.data)

                    return Promise.resolve(response)
                }).catch(error => {
                    return Promise.reject(error)
                }).finally(() => {
                    commit('toggleLoadingOff', null, { root: true })
                });
        },
        /**
       * Endpoint: /client/conference/1/attendees
       * Get all attendees
       */
        async fetchAttendees({ commit }) {
            commit('toggleLoading', { is_loading: true, message: 'Loading Directory' }, { root: true })

            return await asca_api.get('/client/conference/1/attendees')
                .then(response => {
                    //commit('SET_ATTENDEES', response.data.data) //hotfix: attendees too large for storage

                    return Promise.resolve(response)
                }).catch(error => {
                    return Promise.reject(error)
                }).finally(() => {
                    commit('toggleLoadingOffAbsolute', null, { root: true })
                });
        },
    },
};
