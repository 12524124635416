import {Storage} from "@ionic/storage";
import moment from "moment-timezone";
import AppContentService from "@/services/app-content.service";

const storage = new Storage();
await storage.create();

const expiryMinutes = 10

export default {
    namespaced:true,
    state: {
        content: {},
        contentExpiry: null,
    },
    mutations: {
        SET_CONTENT(state, payload) {
            state.content = payload.content
            state.contentExpiry = payload.expiry
        },
    },
    actions: {
        async fetchAppContent({ commit }) {
            if (moment().isBefore(this.state.contentExpiry) && this.state.content != null) {
                return Promise.resolve(this.state.content)
            }

            return await AppContentService.getAppContent().then(response => {
                const content = response.data
                if (content == null) {
                    return;
                }
                const expiry = moment().add(expiryMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                commit('SET_CONTENT', {content, expiry})
                return Promise.resolve(content)
            }).catch(error => {
                console.log(error.message);
                return Promise.reject(error)
            });
        },
    },
};
