/* ASCA default assets */
const defaultAssets = {
    logoLocation: "/assets/images/ASCA-logo-white.svg",
    logoDarkLocation: "/assets/images/ASCA-logo-dark.svg",

    sponsorAd_bannerSrc: '/assets/images/asca-2022-ad-banner.png',
    sponsorAd_overlaySrc: '/assets/images/asca-2022-ad-overlay.png',

    featuredSession_bannerSrc: '/assets/images/general-session-block-bg.svg',
}

export default defaultAssets;
