<template>
  <ion-app
    id="ion-app"
    class="bg-main"
  >
    <AppLayout />
    <AppMenu
      v-if="showMenu"
      :logo="logoLocation"
      :conference-name="activeConference?.name"
      :primary-color="activeConference?.branding.colors.primary"
      :menu-style="`background-image: url('${activeConference?.branding.images.menu}');`"
    />
    <ion-tabs>
      <ion-router-outlet id="main-content" />
      <ion-tab-bar
        v-if="showTabs"
        slot="bottom"
        class="ion-padding"
      >
        <ion-tab-button
          tab="dashboard"
          href="/dashboard"
        >
          <HomeIcon
            class="h-6 w-6"
          />
        </ion-tab-button>

        <ion-tab-button
          tab="sessions"
          href="/sessions"
        >
          <CalendarIcon
            class="h-6 w-6"
          />
        </ion-tab-button>

        <ion-tab-button
          tab="directory"
          href="/directory"
        >
          <UserGroupIcon
            class="h-6 w-6"
          />
        </ion-tab-button>
        <ion-tab-button
          tab="notifications"
          href="/notifications"
        >
          <BellIcon
            class="h-6 w-6"
          />
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-app>
</template>

<script lang="ts">
import {IonApp, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from '@ionic/vue';
import { defineComponent } from 'vue';
import AppMenu from "@/components/AppMenu.vue";
import defaultAssets from "@/default-assets";
import {mapState} from "vuex";
import AppLayout from "@/layouts/AppLayout.vue";
import {BellIcon, CalendarIcon, HomeIcon, UserGroupIcon} from "@heroicons/vue/outline";
import {useRoute} from "vue-router";

export default defineComponent({
    name: 'App',
    components: {
        CalendarIcon, IonTabs, IonTabButton, HomeIcon, BellIcon, UserGroupIcon, IonTabBar,
        AppLayout,
        AppMenu,
        IonApp,
        IonRouterOutlet,
    },
    data() {
        return {
            logoLocation: defaultAssets.logoLocation,
        }
    },
    computed: {
        isAuthenticated() {
            return this.$store.state.user.is_authenticated;
        },
        excludedPages() {
            return ['Introduction', 'Login'];
        },
        showTabs() {
            return this.isAuthenticated && !this.excludedPages.includes(this.$route.name)
        },
        showMenu() {
            return this.isAuthenticated && !this.excludedPages.includes(this.$route.name)
        },
        ...mapState('conferences', ['activeConference'])
    },
    methods: {
        isCurrentRoute(path: string) {
            const route = useRoute();

            return route.path.includes(path);
        }
    }
});
</script>

<style scoped>
ion-tab-button {
  --color-selected: rgba(10, 132, 255, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.15s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
