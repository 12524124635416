import { loadView } from './index';

export default [
    {
        path: '/login',
        name: 'Login',
        component: loadView('LoginIndex'),
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/firstTimeLogin',
        name: 'FirstTimeLogin',
        component: loadView('FirstTimeLogin'),
        props: true,
        meta: {
            title: 'Login'
        }
    }
]
