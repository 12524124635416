import { Capacitor } from '@capacitor/core';
import { loadView } from './index';
import { StatusBar, Style } from '@capacitor/status-bar';

const isStatusBarAvailable = Capacitor.isPluginAvailable('StatusBar') && Capacitor.getPlatform() === 'ios';
export default [
    {
        path: '/information',
        name: 'Information',
        beforeEnter: () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        component: loadView('Information/InformationIndex'),
        meta: {
            title: 'Information'
        }
    },
]
