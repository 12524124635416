import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLayout = _resolveComponent("AppLayout")!
  const _component_AppMenu = _resolveComponent("AppMenu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_CalendarIcon = _resolveComponent("CalendarIcon")!
  const _component_UserGroupIcon = _resolveComponent("UserGroupIcon")!
  const _component_BellIcon = _resolveComponent("BellIcon")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, {
    id: "ion-app",
    class: "bg-main"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AppLayout),
      (_ctx.showMenu)
        ? (_openBlock(), _createBlock(_component_AppMenu, {
            key: 0,
            logo: _ctx.logoLocation,
            "conference-name": _ctx.activeConference?.name,
            "primary-color": _ctx.activeConference?.branding.colors.primary,
            "menu-style": `background-image: url('${_ctx.activeConference?.branding.images.menu}');`
          }, null, 8, ["logo", "conference-name", "primary-color", "menu-style"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet, { id: "main-content" }),
          (_ctx.showTabs)
            ? (_openBlock(), _createBlock(_component_ion_tab_bar, {
                key: 0,
                slot: "bottom",
                class: "ion-padding"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_tab_button, {
                    tab: "dashboard",
                    href: "/dashboard"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_HomeIcon, { class: "h-6 w-6" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_tab_button, {
                    tab: "sessions",
                    href: "/sessions"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CalendarIcon, { class: "h-6 w-6" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_tab_button, {
                    tab: "directory",
                    href: "/directory"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_UserGroupIcon, { class: "h-6 w-6" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_tab_button, {
                    tab: "notifications",
                    href: "/notifications"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BellIcon, { class: "h-6 w-6" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}