import { loadView } from './index';
import authGuard from './../helpers/authGuard'

//import DelegateIndex from '../views/Delegate/DelegateIndex.vue';

export default [
    {
        path: '/ambassador',
        alias: '/ambassador',
        name: 'AmbassadorScanning',
        component: loadView('Ambassador/AmbassadorIndex'),
        beforeEnter: authGuard,
        meta: {
            title: 'Ambassador Scanning'
        }
    }
]
