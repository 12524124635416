import asca_api from './asca_api';
import store from '../store';

class ConferenceService {
    getActiveConferenceAttendees() {
        const userUuid = store.getters.get_user_profile.uuid;
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        return this.getAttendees(activeConferenceId);
    }

    getAttendees(conferenceId) {
        return asca_api.get('v2/client/conference/' + conferenceId + '/attendees').then((response) => {
            return response.data
        });
    }

    getFeaturedSession() {
        const userUuid = store.getters.get_user_profile.uuid;
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        return asca_api.get('v2/client/conference/' + activeConferenceId + '/featured-session').then((response) => {
            return response.data
        });
    }

    getAllConferences() {
        return asca_api.get("v2/public/conferences").then((response) => {
            return response.data
        });
    }

    getFutureConferences() {
        return asca_api.get("v2/public/future-conferences").then((response) => {
            return response.data
        });
    }

    async postSteps(steps: any) {
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        const response = await asca_api.post('v2/client/conference/' + activeConferenceId + '/steps', {
            'steps': steps,
        });
        return response.data;
    }
}

export default new ConferenceService();
