import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_alert = _resolveComponent("ion-alert")!

  return (_openBlock(), _createBlock(_component_ion_alert, {
    "is-open": $setup.isOpen,
    header: "Update Required",
    "sub-header": "A new version of the app is available.",
    message: "Please update the app to continue using it.",
    buttons: $setup.alertButtons,
    onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => ($setup.setOpen(false)))
  }, null, 8, ["is-open", "buttons"]))
}