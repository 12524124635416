import { Module } from 'vuex';


export interface PageState {
    is_loading: boolean,
    current_loaders: number,
    loading_message: string,
    all_sessions: boolean
    is_notification: boolean,
    maintenance_mode: boolean,
    notification_title: string,
    notification_body: string,
    notification_data: object
  }
  
const defaultState: PageState = {
    is_loading: false,
    current_loaders: 0,
    loading_message: 'Loading',
    all_sessions: true,
    is_notification: false,
    maintenance_mode: false,
    notification_title: '',
    notification_body: '',
    notification_data: null
};
  
const pageStore: Module<PageState, any> = {
    state: defaultState,
    mutations: {
        async toggleLoading(state, loadingNextState) {
            state.is_loading = loadingNextState.is_loading

            if(loadingNextState.is_loading) //track number of loaders to ensure a loader never ends prematurely
                state.current_loaders++
            else
                state.current_loaders--

            state.loading_message = loadingNextState.message ?? defaultState.loading_message
            return Promise.resolve(true)
        },
        toggleLoadingOff(state) {
            state.is_loading = false
            if(state.current_loaders > 0)
                state.current_loaders--
        },
        toggleLoadingOffAbsolute(state) {
            state.is_loading = false
            state.current_loaders = 0
        },
        all_sessions(state, nextState) {
            state.all_sessions = nextState
        },
        async toggleNotification(state, loadingNextState) {
            state.is_notification = loadingNextState.is_notification

            state.notification_title = loadingNextState.notification_title ?? defaultState.notification_title
            state.notification_body = loadingNextState.notification_body ?? defaultState.notification_body
            state.notification_data = loadingNextState.notification_data ?? defaultState.notification_data
            return Promise.resolve(true)
        },
        enableMaintenance(state) {
            state.maintenance_mode = true
        },
    },
    actions: { 
    },
    getters: {
        is_loading(state) {
            return state.is_loading
        },
        notification(state) {
            return state
        },
        maintenance_mode(state) {
            return state
        },
        current_loaders(state) {
            return state.current_loaders
        },
        loading_message(state) {
            return state.loading_message
        },
        all_sessions(state) {
            return state.all_sessions
        },
    }
};

export default pageStore;