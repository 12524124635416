import asca_api from '../services/asca_api';
import AuthService from '../services/auth.service';
import router from '../router'

const setup = (store) => {
    //inject token
    asca_api.interceptors.request.use(
        async (config) => {
            const token = await AuthService.getToken();
            if (token)
                config.headers.common['Authorization'] = 'Bearer ' + token
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    //error handling
    asca_api.interceptors.response.use(
        (response) => {
            //store.commit('toggleLoadingOff')
            return response;
        },
        (error) => { //TODO: change alerts to ionic/native device alert with dismission bar
            if (!(router.currentRoute.value.name == 'Login') && error.response?.status === 401) {
                alert('Permission Error: you do not have a valid login.  Please authorize again.');
                const loginpath = window.location.pathname;
                router.push({ name: 'Login', query: { from: loginpath } });
                //router.push('/login')
            } else if (!(router.currentRoute.value.name == 'Login') && error.response?.status === 403) {
                alert('You do not have permission to view that Model or Resource.');
            } else if (!(router.currentRoute.value.name == 'Login')) {
                //alert('Server Error 333: Please try again; if the error persists, screenshot this code and report to mobile app support desk for assistance.');
            } else if (error.response?.status === 503) {
                store.commit('enableMaintenance')
            }
            store.commit('toggleLoadingOffAbsolute')
            return Promise.reject(error);
        }
    );

    //refresh token
    /*
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/signin" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const rs = await axiosInstance.post("/auth/refreshtoken", {
              refreshToken: TokenService.getLocalRefreshToken(),
            });

            const { accessToken } = rs.data;

            store.dispatch('auth/refreshToken', accessToken);
            TokenService.updateLocalAccessToken(accessToken);

            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
  */
}

export default setup;
