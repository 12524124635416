import asca_api from './asca_api';
import store from '../store';

class UserService {
    getPublicContent() {
        return asca_api.get('all');
    }

    getUserData() {
        const userUuid = store.getters.get_user_profile.uuid;
        return asca_api.get('v2/client/user/' + userUuid)
            .then((response) => {
                return response.data;
            });
    }

    getUserConferences() {
        const userUuid = store.getters.get_user_profile.uuid;
        return asca_api.get('v2/client/user/' + userUuid + '/conferences')
            .then((response) => {
                return response.data;
            });

    }

    getActiveConference() {
        const userUuid = store.getters.get_user_profile.uuid;
        return asca_api.get('v2/client/user/' + userUuid + '/active-conference')
            .then((response) => {
                return response.data;
            });
    }

    async getActiveConferenceLeaderboard(limit = 10) {
        const userUuid = store.getters.get_user_profile.uuid;
        const response = await asca_api.get('v2/client/user/' + userUuid + '/active-conference-leaderboard', {
            params: {
                limit: limit
            }
        });
        return response.data;
    }

    async getActiveConferenceLeaderboardUserRank() {
        const userUuid = store.getters.get_user_profile.uuid;
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        const response = await asca_api.get('v2/client/conference/' + activeConferenceId + '/user/' + userUuid + '/leaderboard');
        return response.data;
    }

    getNextSession() {
        const userUuid = store.getters.get_user_profile.uuid;
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        return asca_api.get('v2/client/conference/' + activeConferenceId + '/user/' + userUuid + '/next-session')
            .then((response) => {
                return response.data;
            });
    }

    storeCheckin(email) {
        const userUuid = store.getters.get_user_profile.uuid;
        return this.getActiveConference().then((conference) => {
            const activeConferenceId = conference.data.id
            return asca_api.post('v2/client/conference/' + activeConferenceId + '/ambassador/storeCheckin', { email: email })
                .then((response) => {
                    return response.data;
                });
        });

    }

    storeQrScan(qrCodeId) {
        const userUuid = store.getters.get_user_profile.uuid;
        return asca_api.post('v2/client/user/' + userUuid + '/qr-codes/' + qrCodeId)
            .then((response) => {
                return response.data;
            });
    }

    storeQrCodeEntry(hashId) {
        const userUuid = store.getters.get_user_profile.uuid;
        return asca_api.post('v2/client/user/' + userUuid + '/qr-codes/hash-id/' + hashId)
            .then((response) => {
                return response.data;
            });
    }

    deleteAccount() {
        const userUuid = store.getters.get_user_profile.uuid;
        return asca_api.delete('v2/client/user/' + userUuid)
            .then((response) => {
                return response;
            });
    }

    handoutTerms(accept) {
        const userUuid = store.getters.get_user_profile.uuid;
        return this.getActiveConference().then((conference) => {
            const activeConferenceId = conference.data.id
            return asca_api.post('v2/client/conference/' + activeConferenceId + '/user/' + userUuid + '/terms', { accept: accept })
                .then((response) => {
                    return response.data;
                });
        });

    }

    claimEmail(email) {
        const userUuid = store.getters.get_user_profile.uuid;
        return asca_api.post('v2/client/me/add-email', { email: email }).then((response) => {
            return response;
        });
    }

    sendNotes() {
        const userUuid = store.getters.get_user_profile.uuid;
        return this.getActiveConference().then((conference) => {
            const activeConferenceId = conference.data.id
            return asca_api.post('v2/client/conference/' + activeConferenceId + '/user/' + userUuid + '/send-notes')
                .then((response) => {
                    return response.data;
                });
        });
    }

    editUser(data) {
        const userUuid = store.getters.get_user_profile.uuid;
        return asca_api.post('v2/client/user/' + userUuid, data).then((response) => {
            return response;
        });
    }

    subscribeToNewsletter(data) {
        return asca_api.post('v2/client/me/subscribe-to-newsletter', data).then((response) => {
            return response;
        });
    }

    getAgenda() {
        const userUuid = store.getters.get_user_profile.uuid;
        const activeConferenceId = store.getters.get_user_profile.active_conference_id;
        return asca_api.get('v2/client/conference/' + activeConferenceId + '/user/' + userUuid + '/sessions').then((response) => {
            return response.data;
        });
    }
}

export default new UserService();
