import asca_api from './asca_api';
import store from '../store';

class AppContentService {
    getAppContent() {
        return asca_api.get('v2/client/app-content').then((response) => {
            return response;
        });
    }
}

export default new AppContentService();
