<template>
  <div />
</template>
<script lang="ts">
import { App } from "@capacitor/app";

export default {
    async mounted() {
        App.addListener("appStateChange", (state) => {
            if (state.isActive) {
                this.checkPushNotifications();
            }
        });
        this.checkPushNotifications();
    },
    methods: {
        async checkPushNotifications() {
            await this.$store.dispatch('fcm/registerPushNotifications');
        }
    },
};
</script>
