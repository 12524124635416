import asca_api from '../../services/asca_api';
import {Storage} from "@ionic/storage";
import DelegateService from "@/services/delegate.service";

const storage = new Storage();
await storage.create();

export default {
    namespaced:true,
    state: {
        question: {},
        answered: []
    },
    mutations: {
        /**
       * STORE question
       */
        SET_QUESTION(state, question) {
            state.question = question
        },
        /**
       * RESET question
       */
        RESET_QUESTION(state) {
            state.timeslots = {}
        },
        /**
       * STORE new user response
       */
        ADD_ANSWER(state, question) {
            if (state.answered) {
                const index = state.answered.findIndex(item => item.id === question.id)
                if (index === -1) {
                    state.answered.push(question)
                } else {
                    state.answered[index] = question
                }
            } else {
                state.answered = [question]
            }
        },
    },
    getters: {
        getQuestion: (state) => state.question,
        getAnswered: (state) => state.answered
    },
    actions: {
        /**
       * Endpoint: /client/conference/1/delegate/getQuestion
       * Get active questoin + store
       */
        async fetchQuestion({ commit }, payload) {
            if(payload.first)
                commit('toggleLoading', { is_loading: true, message: 'Fetching Poll' }, { root: true })

            return await DelegateService.getActiveConferenceQuestion()
                .then(response => {
                    commit('SET_QUESTION', response.data.data)

                    return Promise.resolve(response)
                }).catch(error => {
                    return Promise.reject(error)
                }).finally(() => {
                    if(payload.first)
                        commit('toggleLoadingOff', null, { root: true })
                });
        },
        /**
       * Endpoint: /client/conference/1/delegate/storeAnswer/{question:id}
       * Toggle whether session is on user's agenda
       */
        async storeAnswer ({ commit }, payload) {
            commit('toggleLoading', { is_loading: true, message: 'Casting Vote' }, { root: true })

            return await DelegateService.submitAnswer(payload.id, payload.answer)
                .then(response => {
                    commit('ADD_ANSWER', payload)

                    return Promise.resolve(response)
                }).catch(error => {
                    return Promise.reject(error)
                }).finally(() => {
                    commit('toggleLoadingOff', null, { root: true })
                });
        }
    },
};
