import { loadView } from './index';
import { StatusBar, Style } from '@capacitor/status-bar';
import store from '../store/index'
import { Capacitor } from '@capacitor/core';

const isStatusBarAvailable = Capacitor.isPluginAvailable('StatusBar') && Capacitor.getPlatform() === 'ios';

export default [
    {
        path: '/sessions',
        name: 'Sessions',
        component: loadView('Sessions/SessionIndex'),
        meta: {
            title: 'Sessions'
        },
        beforeEnter: (to, from, next) => { //route entry only, same route calls handled by watcher in component
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }


            if (typeof to.query.all_sessions !== 'undefined')
                store.commit('all_sessions', (to.query.all_sessions === 'true')) //need to convert query params from string to type

            next()
        }
    },
    {
        path: '/sessions/:session_id',
        name: 'Session',
        component: loadView('Sessions/Single'),
        beforeEnter: () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        meta: {
            title: 'Session'
        }
    },
    {
        path: '/sessions/:session_id/notes',
        name: 'SessionNotes',
        component: loadView('Notes/Note'),
        beforeEnter: () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        meta: {
            title: 'Notes'
        }
    },
    {
        path: '/notes',
        name: 'Notes',
        component: loadView('Notes/NoteIndex'),
        beforeEnter: () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        meta: {
            title: 'Notes',
            showAd: true,
        }
    },
    {
        path: '/sessions/:session_id/eval',
        name: 'SessionEvaluation',
        component: loadView('Sessions/Evaluation'),
        beforeEnter: () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        meta: {
            title: 'Session Evaluation'
        }
    },
    {
        path: '/sessions/:session_id/handouts',
        name: 'SessionHandouts',
        component: loadView('Sessions/Handouts'),
        beforeEnter: () => {
            if (isStatusBarAvailable) {
                StatusBar.setStyle({ style: Style.Light });
            }
        },
        meta: {
            title: 'Session Handouts'
        }
    }
]
