//import axios from 'axios'
import asca_api from "./asca_api";
import store from "../store";
import moment from "moment-timezone";
import { Storage } from "@ionic/storage";

class AuthService {
    async registerFcmToken(fcm_token: string) {
        const device = await store.getters['get_user_device'];
        const email = await store.getters['get_user_email'];
        return asca_api
            .post("v2/auth/register-fcm-token", {
                fcm_token: fcm_token,
                device_name: device,
                email: email,
            })
            .then((response) => {
                return response;
            });
    }

    // Validate E-mail address
    async validateEmail(user) {
        return asca_api
            .post("v2/auth/validateEmail", {
                email: user.email,
                device_name: user.device_name,
            })
            .then((response) => {
                return response;
            });
    }

    // validateVerificationCode
    async validateVerificationCode(user) {
        return asca_api
            .post("auth/validateVerificationCode", {
                email: user.email,
                code: user.verification_code,
                password: user.password,
                device_name: user.device_name,
                fcm_token: user.fcm_token,
            })
            .then((response) => {
                return response;
            });
    }

    // resetPassword
    async resetPassword(user) {
        const data = {
            email: user.email,
            code: user.verification_code,
            password: user.password,
            device_name: user.device_name,
            fcm_token: user.fcm_token,
        };
        return asca_api.post("auth/password-reset", data).then((response) => {
            return response;
        });
    }

    // Login
    async login(user) {
        return asca_api
            .post("auth/getToken", {
                email: user.email,
                password: user.password,
                device_name: user.device_name,
                fcm_token: user.fcm_token,
            })
            .then((response) => {
                return response;
            });
    }

    // Logout
    async logout() {
        const storage = new Storage();
        await storage.create();
        return asca_api
            .post("client/me/logout", {
                device_name: await storage.get("device_name"),
            })
            .then((response) => {
                store.commit("toggleLoadingOffAbsolute");
                store.commit("logOut");
                store.commit("timeslots/RESET_TIMESLOTS");
                return response;
            })
            .finally(async () => {
                const device_name = await storage.get("device_name");
                const fcm_token = await storage.get("fcm_token");

                await storage.clear();

                await storage.set("device_name", device_name);

                //articial timer sets to ensure users get another pull after logging in
                await storage.set(
                    "last_session_fetch",
                    JSON.stringify(moment().subtract(6, "minutes"))
                );
                await storage.set(
                    "last_directory_fetch",
                    JSON.stringify(moment().subtract(6, "minutes"))
                );
            });
    }

    async getToken() {
        const storage = new Storage();
        await storage.create();
        return await storage.get("token");
    }
}

export default new AuthService();
